<script setup>
  const { event } = defineProps(['event']);

  const whiteCorner = ref({ last_name: '', thumbnail: null });
  const blackCorner = ref({ last_name: '', thumbnail: null });

  function onFightSelect(fight) {
    if (fight) {
      whiteCorner.value = fight.whiteCorner;
      blackCorner.value = fight.blackCorner;
    }
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <UpcomingEventCard high :event="event" @fightSelect="onFightSelect">
    <h2>
      <FightTitleCombo small center>
        <template v-slot:whiteCorner>{{ event.white_corner?.last_name ?? 'TBD' }}</template>
        <template v-slot:blackCorner>{{ event.black_corner?.last_name ?? 'TBD' }}</template>
      </FightTitleCombo>
    </h2>
    <div class="tournament">
      <h4>
        <IconPlus />
        {{ useTournamentSize(event.size) }} {{ event.tournament_title }}
      </h4>
      <TickerTape v-if="!event.predraw" :items="event.tournament_fighter_names" class="ticker" />
    </div>
  </UpcomingEventCard>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .event-card {
    h2 {
      width: 100%;
      margin-top: px(20);
    }

    .tournament {
      display: flex;
      align-items: center;
      flex-direction: column;

      gap: 8px;
      margin: 32px 0 -16px 0;

      h4 {
        display: flex;
        align-items: center;

        @include title-s;
        font-size: px(32);

        gap: px(8);

        svg {
          width: px(10);
          margin-top: px(-6);
        }
      }

      .ticker {
        width: 100%;
        max-width: px(498);
      }
    }

    @include break-large {
      .tournament {
        .ticker {
          max-width: 40vw;
        }
      }
    }

    @include break-medium {
      .tournament {
        .ticker {
          max-width: px(276);
        }
      }
    }

    @include break-small {
      &:deep(.right-side) {
        .top {
          h3 {
            margin-top: px(96);
          }
        }
      }

      .tournament {
        margin-top: px(14);

        .ticker {
          max-width: none;
          width: 100vw;
        }
      }

      &.predraw {
        &:deep(.right-side) {
          height: px(120);

          .predraw {
            .content {
              display: none;
            }
          }
        }
      }
    }
  }
</style>
